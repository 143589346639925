import { HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from './../../services/users.service';
import { FormControl } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public http: HttpClientModule, public dialogRef: MatDialogRef<NoteComponent>) { }

  user = this.data.user;
  note = new FormControl(this.user.privateProperties && this.user.privateProperties.notes || '');

  ngOnInit(): void {  }

  save(){
    this.dialogRef.close({result: true, notes: this.note.value});
  }

}
