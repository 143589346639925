import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() {
  }

  private themeSubject = new BehaviorSubject<string>('light');
  public theme$  = this.themeSubject.asObservable();

  initTheme() {
    let theme = 'light';
    if (localStorage.getItem('theme') && ['dark', 'light'].includes(localStorage.getItem('theme'))) {
      theme = localStorage.getItem('theme');
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      theme = 'dark';
    }
    this.setTheme(theme);
  }


  setTheme(theme: string) {
    if (theme === 'dark') {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
    this.themeSubject.next(theme);
  }

}
