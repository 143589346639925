import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NetworkHelper } from './network-helper.service';
import { Observable, of, throwError } from 'rxjs';
import { User } from '../../shared/models/user.model';
import { catchError, map, mapTo, shareReplay, tap } from 'rxjs/operators';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { parseZone } from 'moment-mini';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private networkHelper: NetworkHelper) {
  }

  get isAdminAuthenticated() {
    if (!localStorage.getItem('token')) {
      return of(false);
    }
    return this.getCurrentAdmin().pipe(mapTo(true), catchError(err => of(false)));
  }

  private currentAdmin$: Observable<User>;

  getCurrentAdmin() {
    if (!localStorage.getItem('token')) {
      return throwError('No token found');
    }

    if (!this.currentAdmin$) {
      this.currentAdmin$ = this.networkHelper.get<User>(`${environment.apiUrl}/user`, {}).pipe(
        shareReplay(1),
      );
    }
    return this.currentAdmin$;
  }

  updateBalance(type, userId, currency, quantity, note) {
    const data = {userId, currency, quantity};
    if (note) {
      data['note'] = note;
    }
    let endPoint = 'adjustment';
    if (type === 1) {
      endPoint = 'withold';
      data['expires'] = 0;
    }
    if (type === 2) {
      endPoint = 'release';
    }
    return this.networkHelper.post(`${environment.apiUrl}/admin/${endPoint}`, data);
  }

  getSummary(symbol) {
    const tzShift = parseZone().utcOffset();
    return this.networkHelper.get(`${environment.apiUrl}/admin/summary/${symbol}/${tzShift}`);
  }

  getTradeInfoChart(symbol, startDate, endDate) {
    const timeZoneShift = parseZone().utcOffset();
    return this.networkHelper.get(`${environment.apiUrl}/admin/trades-amount`, {symbol, startDate, endDate, timeZoneShift});
  }

  getTradesCount(symbol, startDate, endDate) {
    const timeZoneShift = parseZone().utcOffset();
    return this.networkHelper.get(`${environment.apiUrl}/admin/trades-count`, {symbol, startDate, endDate, timeZoneShift});
  }

  getTradesFee(symbol, startDate, endDate) {
    const timeZoneShift = parseZone().utcOffset();
    return this.networkHelper.get(`${environment.apiUrl}/admin/trades-fees`, {symbol, startDate, endDate, timeZoneShift});
  }

  activateUser(id){
    return this.networkHelper.post(`${environment.apiUrl}/auth/activate-email`, {userId: id});
  }
}
