<mat-toolbar color="primary">
    <span>ATAIX</span>
    <div class="menu">
        <ng-container *ngIf="admin.isAdminAuthenticated | async; else publicTemplate">
            <div [routerLink]="['/dashboard']" *ngxPermissionsOnly="['admin', 'support-admin']" routerLinkActive="active">Dashboard</div>
            <div [routerLink]="['/rmd']" *ngxPermissionsOnly="['treasurer', 'admin', 'support-admin']" routerLinkActive="active">Hedging</div>
            <div [routerLink]="['/users']" routerLinkActive="active" *ngxPermissionsExcept="['treasurer']">Users</div>
            <div [routerLink]="['/sessions']" *ngxPermissionsOnly="['admin', 'support-admin']" routerLinkActive="active">Sessions</div>
            <div [routerLink]="['/orders/open']" *ngxPermissionsOnly="['admin', 'support-admin']" routerLinkActive="active">Open orders</div>
            <div [routerLink]="['/orders/trades']" *ngxPermissionsOnly="['admin', 'support-admin']" routerLinkActive="active">Trades</div>
            <div [routerLink]="['/orders/history']" *ngxPermissionsOnly="['admin', 'support-admin']" routerLinkActive="active">Order History</div>
            <div [routerLink]="['/payouts']" *ngxPermissionsOnly="['admin', 'support-admin']" routerLinkActive="active">Payouts</div>
            <div [routerLink]="['/transactions']" *ngxPermissionsExcept="['treasurer']" routerLinkActive="active">Transactions</div>
            <div [routerLink]="['/exchanges/transfers']" *ngxPermissionsOnly="['admin', 'support-admin']" routerLinkActive="active">Transfers</div>
            <div [routerLink]="['/exchanges/quick-exchanges']" *ngxPermissionsOnly="['admin', 'support-admin']" routerLinkActive="active">Quick Exchanges</div>
            <div [routerLink]="['/settings']" *ngxPermissionsOnly="['admin']" routerLinkActive="active">Settings</div>
            <div [routerLink]="['/crypto']" *ngxPermissionsOnly="['admin']" routerLinkActive="active">Crypto</div>
            <div *ngIf="!env.production" [routerLink]="['/projects']" routerLinkActive="active"><span *ngxPermissionsOnly="['admin']">Projects</span></div>
            <div (click)="auth.logout().subscribe()">Logout</div>
        </ng-container>
        <ng-template #publicTemplate>
            <div [routerLink]="['/auth/login']">Login</div>
        </ng-template>
        <div *ngIf="themeService.theme$ | async as theme">
            <button mat-mini-fab (click)="toggleTheme(theme === 'dark' ? 'light' : 'dark')">
        <mat-icon *ngIf="theme === 'light'">bedtime</mat-icon>
        <mat-icon *ngIf="theme === 'dark'">wb_sunny</mat-icon>
      </button>
        </div>

    </div>

</mat-toolbar>
