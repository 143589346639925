<h1 mat-dialog-title>Notes</h1>
<div mat-dialog-content>
    <mat-form-field class="note-form">
        <mat-label>Leave a Note</mat-label>
        <textarea [formControl]='note' class='note-textarea' matInput ></textarea>
    </mat-form-field>
</div>
<div class='note-buttons' mat-dialog-actions>
    <button mat-button mat-dialog-close="">Cancel</button>
    <button mat-button (click)="save()">Confirm</button>
</div>