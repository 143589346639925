<h1 mat-dialog-title>Reason</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput cdkFocusInitial [(ngModel)]="reasonText">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="confirm()" >Confirm</button>

</div>
