import { Component } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { AdminService } from '../../services/admin.service';
import { ThemeService } from '../../services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  env = environment;

  constructor(public auth: AuthService, public admin: AdminService, public themeService: ThemeService) {
   }

  toggleTheme(theme) {
    this.themeService.setTheme(theme);
    localStorage.setItem('theme', theme);
  }
}
