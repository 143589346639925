import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { LayoutComponent } from './core/components';
import {AuthService} from './core/services/auth.service';
import { NgxPermissionsGuard } from 'ngx-permissions';
import {CryptoComponent } from './crypto/crypto.component';


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'rmd',
        loadChildren: () => import('./rmd/rmd.module').then(m => m.RmdModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'treasurer', 'support-admin'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['treasurer'],
            redirectTo: '/rmd'
          }
        }
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'support-admin'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'transactions',
        loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            except: ['treasurer'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'sessions',
        loadChildren: () => import('./session/session.module').then(m => m.SessionModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'support-admin'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'exchanges',
        loadChildren: () => import('./exchange/exchange.module').then(m => m.ExchangeModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'support-admin'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'crypto',
        loadChildren: () => import('./crypto/crypto.module').then(m => m.CryptoModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'support-admin'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'payouts',
        loadChildren: () => import('./payouts/payouts.module').then(m => m.PayoutsModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['admin', 'support-admin'],
            redirectTo: '/'
          }
        }
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canLoad: [AuthService, NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'admin',
            redirectTo: '/'
          }
        }
      },
      {
        path: '**',
        redirectTo: '/users',
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
