export const environment = {
  production: false,
  authHeaderPrefix: 'ax',
  apiUrl: 'https://stage-api.ataix.com/api',
  tradeUrl: 'https://stage-trade.ataix.com',
  kycUrl: 'https://stage-kyc-new.ataix.com/',
  recaptchaSiteKey: '6Ld3LG0UAAAAAH3WzfH1ZIu98FSoo2O_Luh-nTiw',
  recaptcha3SiteKey: '6Lf5eckZAAAAAKrXFZc-tgrBxUfx8T6l5dJia1QF',
  eurotokenKycId: 'KdAotAnQsIE6NyD1VoD4K',
  roles: ['admin', 'support-admin', 'moderator', 'analytics', 'treasurer']
};


import 'zone.js/dist/zone-error';
