import { Component } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import {AdminService} from './core/services/admin.service';
import {environment} from '../environments/environment';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ataix-role-admin';

  constructor(private theme: ThemeService) {
    this.theme.initTheme();
  }

}
