import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NetworkHelper } from './network-helper.service';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AdminService } from './admin.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanLoad {
  constructor(private networkHelper: NetworkHelper, private adminService: AdminService, private router: Router) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.adminService.isAdminAuthenticated.pipe(
      tap(authState => {
        if (!authState) {
          this.router.navigate(['/auth/login']).then();
        }
      })
    );
  }


  login(email: string, password: string, captcha: string, captchaType: string) {
    return this.networkHelper.post(`${environment.apiUrl}/auth/login`, {captcha, captchaType}, `${email}:${password}`);
  }

  logout() {
    return this.networkHelper.post(`${environment.apiUrl}/auth/logout`, {}).pipe(
      tap(() => localStorage.removeItem('token')),
      tap(() => window.location.reload())
    );
  }
}
