import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss']
})
export class ReasonComponent implements OnInit {

  reasonText = '';
  constructor(public dialogRef: MatDialogRef<ReasonComponent>) { }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close({result: true, message: this.reasonText});
  }

}
