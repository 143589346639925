import { NgModule } from '@angular/core';
import * as components from './components';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgxPermissionsModule} from 'ngx-permissions';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [components.LayoutComponent, components.HeaderComponent, components.ReasonComponent, components.AdjustComponent,
  components.ShowInfoComponent, components.ConfirmComponent, components.NoteComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgxPermissionsModule.forChild(),
    ReactiveFormsModule,
  ]
})
export class CoreModule {
}
