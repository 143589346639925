<h2 mat-dialog-title>
  {{data.type === 0 ? 'Adjustment' : data.type === 1 ? 'Hold' : 'Release'}}
  ({{data.currency}})</h2>
<mat-dialog-content >
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Amount</mat-label>
      <input #input matInput>
    </mat-form-field>
  </div>
 <div>
   <mat-form-field appearance="fill">
     <mat-label>Notes</mat-label>
     <input #note matInput>
   </mat-form-field>
 </div>



</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button (click)="submit(input.value, note.value)">Confirm</button>
</mat-dialog-actions>
