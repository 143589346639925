import { catchError, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

// third party
import { ToastrService } from 'ngx-toastr';

// app
import { environment } from '../../../environments/environment';
import { AxError } from '../helpers/ataix-error';



@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {

    // Interceptor for backend
    if (req.url.indexOf(environment.apiUrl) !== -1 ) {
      const token = localStorage.getItem('token');
      if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: `${environment.authHeaderPrefix} <${token}>`
          }
        });
      }

      return next.handle(req).pipe(
          filter(event => event instanceof HttpResponse),
          map((event: HttpResponse<any>) => {
              const totalCount = event.headers.get('res-total');
              if (!event.body.status) {
                this.toastr.error(event.body.message);
                if (event.body.code === 401 || event.body.code === 801) {
                  // this.userService.clearUserData();
                }

                throw new AxError(event.body.message, event.body.type);
              } else {
                if (totalCount) {
                  event = event.clone({body: {data: event.body.result, count: totalCount}});
                } else {
                  event = event.clone({body: event.body.result});
                }
                return event;
              }
          }),
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              return throwError(new AxError('Network Error', ''));
            } else {
              return throwError(err);
            }
          })
        );
    } else {
      // For all other third party API calls
      return next.handle(req);
    }
  }
}
