import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { NgxPermissionsModule, NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RequestInterceptor } from './core/services/request.interceptor';
import { MatNativeDateModule } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { AdminService } from './core/services/admin.service';
import { CryptoComponent } from './crypto/crypto.component';


const roleFn = (admin: AdminService, rs: NgxRolesService) => {
  return () => {
    return new Promise((resolve) => {
      admin.getCurrentAdmin().toPromise().then(currentAdmin => {
        if (environment.roles.includes(currentAdmin.role)) {
          rs.addRole(currentAdmin.role, []);
        }
      }).finally(() => resolve(true));
    });
  };
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    MatNativeDateModule,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      newestOnTop: true
    }),
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {siteKey: environment.recaptchaSiteKey} as RecaptchaSettings,
    },
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha3SiteKey},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: roleFn,
      deps: [AdminService, NgxRolesService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
